/* src/components/LandingPage.css */

.landing-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    text-align: center;
    padding: 20px;
  }
  
  .header {
    background: #007BFF;
    color: white;
    padding: 50px 20px;
    border-radius: 8px;
    margin-bottom: 30px;
  }
  
  .header h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .header p {
    font-size: 1.2em;
    margin-bottom: 30px;
  }
  
  .store-buttons img {
    width: 150px;
    margin: 0 10px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .store-buttons img:hover {
    transform: scale(1.1);
  }
  
  .features {
    margin: 40px 0;
  }
  
  .features h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .feature-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .feature-item {
    width: 30%;
    min-width: 250px;
    margin-bottom: 20px;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .feature-item h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .feature-item p {
    font-size: 1em;
  }
  
  .footer {
    margin-top: 40px;
    font-size: 0.9em;
    color: #666;
  }
  